@import '../../scss/config';

.main-app-bar-lg {
  position: relative;
  z-index: $app-header-z-index;
  // background-color: $main-app-color;
  background-color: #fff;
  .custom-container {
    display: grid;
  }
  .nav-content-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .main-app-bar-logo {
      padding: 2px 0;
      img {
        height: auto;
        width: 97px;
        transition: all 0.25s ease-out;
      }
    }
    .countdown-wrapper {
      width: fit-content;
      min-width: 122px;
      font-size: 26px;
      background-color: #ffde87;
      color: #21406e !important;
      padding: 8px 18px;
      border-radius: 4px;
      font-weight: bold;
    }
  }

  @include mainAppBarHeight;
  transform: translateY(0);
  position: inherit;
  top: 0;
  left: 0;
  right: 0;
  // transition: all 0.25s ease-out;
  display: grid;
  align-items: center;

  display: none;
  @include mediaLg {
    display: grid;
  }
}
