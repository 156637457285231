// .social-modal {
// 	display: grid;
// 	align-content: center;
// 	.modal-wrap {
// 		background-color: #fff;
// 		max-width: 600px;
// 		min-height: 222px;
// 		border-radius: 5px;
// 		margin: 32px auto;
// 		display: grid;
// 		place-items: center;
// 	}
// }

.personal-img-modal,
.identity-face-img-modal,
.identity-back-img-modal {
	display: grid;
	place-items: center;
	.modal-body {
		overflow-y: auto;
		padding: 18px 14px;
		position: absolute;
		background-color: #f7f8fa;
		width: 94%;
		max-width: 600px;
		min-height: 360px;
		height: auto;
		@include mediaLg {
			min-height: 400px;
		}
		box-shadow: 0 12px 14px rgba(#000, 0.1), 0 0 30px rgba(#000, 0.1);
		border-radius: 4px;
		border: 0;
		outline: 0;
		.dropZone {
			// width: 500px;
			// height: 240px;
			width: 90%;
			height: 260px;
			margin: auto;
		}

		.previewFiles {
			display: grid;
			place-items: center;
			.imgPreview {
				position: relative;
				display: grid;
				width: 90%;
				max-height: 90%;
				max-width: 500px;
			}
			img {
				// height: 260px;
				max-width: 100%;
				max-height: 220px;
				object-fit: cover;
				object-position: top center;
				// max-width: 500px;
				border: 1px solid rgba(#000, 0.1);
			}

			.icon-cancel {
				position: absolute;
				top: -16px;
				right: -16px;
				background-color: #fff;
				color: #323232;
				width: 32px;
				height: 32px;
				font-size: 22px;
				border-radius: 50%;
				box-shadow: 0 1px 2px rgba(#000, 0.1);
				display: grid;
				place-items: center;
				border: 1px solid rgba(#000, 0.1);
			}
		}

		.done-btn {
			@include bodyFont-ar;
			width: max-content;
			justify-self: center;
		}
	}
}

.ReactCrop {
	margin-bottom: 16px;
	max-height: 400px;
	overflow: auto !important;
	@include custom-scrollbar;
	img {
		max-width: 100%;
	}
}
.cropped-img {
	max-height: 120px;
}

.personal-img-modal {
	.modal-body {
		display: grid;
		justify-items: center;
		align-content: center;
		align-items: center;
		height: 532px;
		.cropped-img {
			margin: 22px 0;
			display: grid;
			place-items: center;
			img {
				height: 180px;
				border: 1px solid rgba(#000, 0.1);
				border-radius: 50%;
			}
		}
	}
}
