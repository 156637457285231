@import '../../scss/config';

.main-lang-btn {
  span {
    @include mediaLg {
      width: 32px !important;
      height: 24px !important;
      cursor: pointer !important;
    }
  }
}

.lng-span-btn {
  color: #fff;
  display: flex;
  .img {
    align-items: center;
    display: grid;
  }
}
