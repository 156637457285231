@import '../../scss/config';

.main-app-bar-md {
  position: relative;
  z-index: $app-header-z-index;

  .nav-content-wrapper {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    grid-gap: 32px;
    align-items: center;

    .main-app-bar-logo {
      padding: 2px 0;
      img {
        height: auto;
      }
    }

    .nav-lang-wrapper {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 14px;
      align-items: center;

      .main-lang-btn {
        .lng-span-btn {
          color: #000;
        }
      }
    }
  }

  &.light {
    .nav-content-wrapper {
      .nav-lang-wrapper {
        & > ul {
          li {
            a {
              color: #fff;
            }
          }
        }
      }
    }
  }

  @include mainAppBarHeight;
  transform: translateY(0);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  transition: all 0.25s ease-out;
  display: grid;
  align-items: center;

  &.exceeds0 {
    border-radius: 0 0 12px 12px;
    box-shadow: 0 12px 14px rgba(#000, 0.1);
    background-color: #fff;
    z-index: calc(#{$app-header-z-index} + 1);
    // &.scroll-up {
    // }
    // .nav-content-wrapper {
    //   .nav-lang-wrapper {
    //   }
    // }
    &.scroll-down {
      transform: translateY(-100%);
    }
  }

  @include mediaLg {
    display: none;
  }
}

.header-md-drawer {
  .MuiBackdrop-root {
    background-color: rgba(#000, 0.7);
  }

  .MuiDrawer-paper {
    width: 60vw;
    min-width: 252px;

    @include mediaMd {
      width: 35vw;
    }

    .main-app-bar-logo {
      display: flex;
      justify-content: center;
      padding: 32px 12px;
      margin-bottom: 14px;
      border-bottom: 1px solid rgba(#000, 0.1);
      img {
        height: auto;
      }
    }
    ul {
      li {
        a {
          padding: 22px 18px;
          font-size: 18px;
          position: relative;
          font-weight: bold;
          display: grid;
          .active-img-wrap {
            width: 44px;
            height: 2px;
            position: absolute;
            bottom: 0;
          }
          // &.active-link {
          //   .active-img-wrap {
          //     // background-image: url(../../assets/imgs/bgs/active-link-bg.png);
          //   }
          // }
        }
      }
      .nav-link {
        display: grid;
        padding: 22px 18px;
        @include mediaMd {
          padding: 32px;
        }
        font-size: 18px;
        position: relative;
        font-weight: bold;
        &:hover {
          color: #2b71ad;
        }
        &.active-link {
          background-color: #2b71ad;
          color: #fff;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}
