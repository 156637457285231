@import '../../scss/config';

.home-page {
  margin-top: 32px;
  .picae-contanier {
    display: flex;
    justify-content: center;
    .pice-data {
      // width: 40%;
      max-width: 1440px;
      margin: auto;
      height: max-content;
      display: grid;
      margin: 1px;
      gap: 1px;
      .total {
        // background-color: #ffde87;
        // color: #21406e;
        background-color: #f12222;
        color: #fff;
        padding: 10px;
        font-size: 74px;
        font-weight: bold;
        height: min-content;
        word-break: break-all;
        line-height: 1.3;
        border-radius: 0 0 12px 12px;
      }
      .pice-data-desc {
        display: grid;
        grid-template-columns: 59% auto;
        gap: 1px;
        align-content: start;
        font-size: 20px;
        .info-first {
          padding: 4px 8px;
          background-color: rgba($color: #21406e, $alpha: 0.6);
          // height: 134.46px;
          height: 128px;
          color: #fff;
          justify-content: center;
          display: grid;
          align-items: center;
          font-weight: bolder;
        }
        .info-title {
          color: #fff;
          justify-content: center;
          display: grid;
          align-items: center;
          font-weight: bolder;
          // height: 73.31px;
          height: 70px;
          background-color: $main-app-color;
        }
        .info-data {
          background-color: #7cb58e !important;
          color: #fff;
          justify-content: center;
          display: grid;
          align-items: center;
          font-weight: bolder;
          // height: 73.31px;
          height: 70px;
        }
        // .info-cell {
        //   // background-color: rgba(33, 64, 110, 0.6);
        //   &.info-title {
        //     color: #21406e;
        //   }
        // }
      }
    }
  }
  .empty-wrap {
    width: 60%;
  }
  .main-slider {
    direction: ltr !important;
    height: 636px;
    width: 60%;
    margin: 1px;
    margin-right: 0;
    .slick-slide {
      direction: ltr !important;
      height: 636px;
      height: 100%;
      // display: grid !important;
      // place-items: center;
      & > div {
        height: 636px;
        height: 100%;
        // display: grid !important;
        // place-items: center;
      }
      .card {
        max-height: 636px;
        height: 100%;
        // display: grid !important;
        // place-items: center;
      }
      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .slick-list,
    .slick-track {
      height: 100%;
    }
    .slick-arrow {
      z-index: 999999;
      background-color: rgba(33, 64, 110, 0.856);
      width: 42px;
      height: 42px;
      border-radius: 50%;
      display: grid;
      place-items: center;
      svg {
        path {
          fill: #fff;
        }
      }
      &.slick-prev {
        &::before {
          // font-size: 0;
          color: #21406e;
          color: #fff;
        }
      }
      &.slick-next {
        &::before {
          // font-size: 0;
          color: #21406e;
          color: #fff;
        }
      }
    }
  }
  .empty-wrap {
    display: grid;
    padding: 8px 22px;
    place-items: center;
    .empty-p {
      color: #21406e;
      font-weight: bold;
      text-align: center;
    }
  }
}

[dir='rtl'] .slick-prev {
  right: 25px !important;
}
[dir='rtl'] .slick-next {
  left: 25px !important;
}

.congrates-wrap {
  padding: 42px 0;
  img {
    height: 122px;
  }
  h1 {
    color: #21406e;
    font-size: 42px;
  }
}
