#recaptcha-container {
  display: none;
}
@import '~react-image-gallery/styles/scss/image-gallery.scss';

@import './config';
@import './common';
@import './modals';

html {
  scroll-behavior: smooth;
  color: $main-black-color;

  &[lang='ar'] {
    @include bodyFont-ar;
    button {
      @include bodyFont-ar;
    }
  }
  &[lang='en'] {
    @include bodyFont-ar;
    button {
      @include bodyFont-ar;
    }
  }
  // &[lang="en"] {
  // 	@include bodyFont-en;
  // 	button {
  // 		@include bodyFont-en;
  // 		font-weight: bold;
  // 	}
  // }
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  background-color: #fff;

  @include custom-scrollbar;
}

.app {
  min-height: 100vh;
  height: 100%;
  background-color: #f7f8fa;
  grid-template-rows: auto 1fr;

  // &.app-ltr {
  // 	@include bodyFont-en;
  // }

  &.app-rtl {
    @include bodyFont-ar;
  }

  margin: 0;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // @import "./css/fontello.css";
  // @import "./css/ionicons.css";
}

[contenteditable='true'] {
  line-height: 1.8;
  // display: grid;
  // align-items: center;
}
.page-header {
  text-align: center;
  .main-title {
    font-size: 35px;
    font-family: 'Cairo-Bold';
    color: #000;
  }
  .page_link {
    b {
      margin: 0px 10px;
    }
    .home {
      color: $secondary-app-color;
      font-family: 'Cairo-Regular';

      font-size: 18px;
    }
    .page {
      color: $main-app-color;
      font-family: 'Cairo-Regular';

      font-size: 18px;
    }
  }
}
.hero-section {
  .slick-dots {
    bottom: 38%;
  }

  .slick-dots li button:before {
    opacity: 0.84;
    background-color: rgba(0, 0, 0, 0.84);
    font-size: 12px;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  .slick-dots li.slick-active button:before {
    color: #fff;
    width: 30px;
    background-color: #fff;
    height: 10px;
    border-radius: 10px;
  }

  .slick-dots li {
    width: 30px;
    text-align: center;
    justify-content: center;
  }
}

.multi-items {
  .slick-dots li button:before {
    opacity: 0.84;
    background-color: #643a7163;
    font-size: 12px;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
  }

  .slick-dots li.slick-active button:before {
    color: #fff;
    width: 30px;
    background-color: $main-app-color;
    height: 10px;
    border-radius: 10px;
  }
  .slick-list {
    z-index: 2;
  }
  .slick-dots {
    text-align: initial;
    top: -43px;
    @include mediaLLg {
      top: -100px;
    }
    z-index: 1;
    li {
      width: 30px;
      text-align: center;
      justify-content: center;
    }
  }
}

.ant-picker-dropdown {
  z-index: 10500;
  width: 283px;
}

.ant-input:focus,
.ant-input-focused,
.ant-input:hover {
  outline: none;
  border-color: #f7f7f7;
  box-shadow: none;
}

.MuiBox-root {
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #d5d5d500;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5f8b9e8f;
    background-color: #b5b5b5;
    border-radius: 10px;

    &:hover {
      background: #aaa;
    }
  }
}

.ant-form-item-label {
  text-align: initial;
}
